import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modalContent: {
    maxWidth: 580,
  },
  fieldList: {
    display: 'grid',
    gap: spacing(24),
  },
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    padding: spacing(8),
    minWidth: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  label: {
    marginTop: '10px',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.ThirtyTwo,
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    padding: '0px 57px 0 50px',
  },
  select: {
    width: '100%',
  },
  selectLabel: {
    fontWeight: '400',
    width: '100%',
  },
  showReferaSuggestions: {
    margin: '0 0 16px',
  },
}))
