import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from '@refera/ui-web'
import format from 'date-fns/format'

import { getTradesmanSequenceByID } from '_/modules/tradesman-sequence/selectors'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import useStyles from './styles'

const formatDateLabel = dateStr => {
  const date = new Date(dateStr)
  return dateStr ? format(date, "dd/MM/yyyy ', às ' HH:mm'h'") : null
}

const SequenceModal = ({ isOpen, onClose, sequenceId, name = 'Sequência' }) => {
  const styles = useStyles()

  const sequence = useSelector(getTradesmanSequenceByID(sequenceId))

  const modalDescriptionLabel = useMemo(() => {
    if (sequence) {
      const creationDate = formatDateLabel(sequence.createdAt)
      const responsibleName = sequence.createdBy
      return `Sequência criada em ${creationDate}, por ${responsibleName}`
    }
    return ''
  }, [sequence])

  const renderTableBody = useMemo(() => {
    if (sequence) {
      const { candidates } = sequence

      return candidates?.map(cell => {
        const {
          serviceProvider: { companyName },
          appointedAt,
          unappointedAt,
          unappointmentReason,
          isAppointed,
        } = cell

        const reason = isAppointed ? 'Atrelado' : unappointmentReason?.reason || 'Não atrelado'

        return (
          <TableRow>
            <TableCell>
              <Typography>{companyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatDateLabel(appointedAt) || '-'}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatDateLabel(unappointedAt) || '-'}</Typography>
            </TableCell>
            <TableCell>
              <Grid container>
                <Typography className={styles.chipReasons}>{reason}</Typography>
              </Grid>
            </TableCell>
          </TableRow>
        )
      })
    }

    return null
  }, [sequence])

  return (
    <Modal
      open={isOpen}
      title={name}
      onClose={onClose}
      onBackdropClick={onClose}
      actionsButtons={
        <Grid container xs={12} className={styles.buttonsContainer}>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Grid>
      }
    >
      <Grid className={styles.modalConteiner}>
        <Typography>{modalDescriptionLabel}</Typography>
        <Table sx={{ marginTop: '24px' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={styles.columnHeader}>Prestador</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.columnHeader}>Atrelado em</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.columnHeader}>Desatrelado em</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.columnHeader}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody}</TableBody>
        </Table>
      </Grid>
    </Modal>
  )
}

export default SequenceModal
