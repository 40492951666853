import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Dialog, Input, Button, Loader } from '@refera/ui-web'
import { CloseCircle as CloseIcon, TickCircle as TickCircleIcon } from '@refera/ui-icons'

import { getReasonsToRefuseServiceOrder } from '_modules/reasons/actions'
import { refuseServiceOrder } from '_modules/service-orders/actions'
import { reasonToRefuseServiceOrderList } from '_modules/reasons/selectors'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import useToggle from '_hooks/use-toggle'

import Modal from './detail-modal'
import useStyles from './styles'

const TradesmanReasonToRefuseDetailModal = ({ handleModal, open }) => {
  const styles = useStyles()
  const [openDialog, toggleOpenDialog] = useToggle()
  const [openDialogSuccess, toggleOpenDialogSuccess] = useToggle()
  const dispatch = useDispatch()
  const reasonList = useSelector(reasonToRefuseServiceOrderList)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const [reasonRefuse, setReasonRefuse] = useState({})
  const [moreDetails, setMoreDetails] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = useCallback(() => {
    setIsLoading(true)
    dispatch(
      refuseServiceOrder(serviceOrder.id, {
        reasonToRefuse: reasonRefuse.id,
        otherReasonToRefuse: moreDetails,
      })
    )
      .then(() => {
        setIsLoading(false)
        toggleOpenDialog()
        toggleOpenDialogSuccess()
      })
      .catch(() => setIsLoading(false))
  }, [dispatch, serviceOrder.id, reasonRefuse, moreDetails])

  const handleSelect = e => {
    setReasonRefuse(e.target.value)
  }

  const onConfirm = () => {
    navigate('/novos-chamados')
  }

  useEffect(() => {
    dispatch(getReasonsToRefuseServiceOrder())
  }, [])

  const handleCancel = () => {
    handleModal()
    toggleOpenDialog()
  }
  return (
    <>
      {isLoading && <Loader hasBackdrop open={isLoading} label="Aguarde..." />}
      {open && (
        <Modal title="Recusar chamado" handleModal={handleModal} hideActionButtons>
          <Grid className={styles.container}>
            <FormControl fullWidth>
              <Typography className={styles.inputLabel} id="select-label">
                Selecione o motivo da recusa
              </Typography>

              <Select onChange={handleSelect} labelId="select-label">
                {reasonList.map(reasonItem => (
                  <MenuItem key={reasonItem.id} value={reasonItem}>
                    {reasonItem.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Input
                fullWidth
                label="Mais detalhes"
                style={{ fontSize: '16px' }}
                onChange={e => setMoreDetails(e.target.value)}
                required={reasonRefuse.reason === 'Outros'}
                inputProps={{ maxLength: 1000 }}
                multiline
              />
            </FormControl>
          </Grid>
          <Grid className={styles.action}>
            <Button variant="secondary" color="red" onClick={handleModal}>
              Cancelar
            </Button>
            <Button
              disabled={reasonRefuse.reason === ''}
              color="primary"
              variant="primary"
              onClick={toggleOpenDialog}
            >
              Enviar
            </Button>
          </Grid>
        </Modal>
      )}
      <Dialog
        open={openDialog}
        icon={CloseIcon}
        type="error"
        subject="Você tem certeza que deseja recusar este chamado?"
        labelCancel="Desfazer"
        labelApprove="Confirmar"
        onApprove={handleConfirm}
        onCancel={handleCancel}
      />
      <Dialog
        open={openDialogSuccess}
        icon={TickCircleIcon}
        type="success"
        subject="Sucesso!"
        description="Ação executada com sucesso."
        onApprove={onConfirm}
      />
    </>
  )
}

TradesmanReasonToRefuseDetailModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
}

export default React.memo(TradesmanReasonToRefuseDetailModal)
