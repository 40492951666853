import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Button, IconButton, Loader, Toast, Alert } from '@refera/ui-web'
import * as Modal from '_components/modal/generic-modal'
import { AddCircle as AddCircleIcon, MinusCirlce as MinusCircleIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Grid } from '@mui/material'
import { Typography, TextField, Select, MenuItem, FormControl } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import {
  getProviderSuggestionsListSelector,
  getProviderSuggestionsListSelectorLoading,
  providerSuggestionsSelector,
  providersResultWithoutPaginationSelector,
} from '_modules/provider/selectors'
import { getProvidersWithoutPagination } from '_modules/provider/actions'
import { REGISTER_STATUS } from '_utils/constants'
import { reorder } from '_utils/helpers'
import DragAndDrop from '_components/common/drag-and-drop'

import useStyles from './styles'
import { createTradesmanSequence } from '_modules/tradesman-sequence/actions'
import { getServiceOrder, getGenericParameters } from '_modules/service-orders/actions'
import Loading from '_/components/loading'
import { GENERIC_PARAMETERS } from '_/utils/constants/service-order'
import useIsGenericParamActive from '_/hooks/use-is-generic-param-active'
// import { ControlledSwitch } from '_/components/inputs'

const EMPTY_ITEM = { name: null, value: null }
const INITIAL_STATE = { tradesman: [EMPTY_ITEM, EMPTY_ITEM, EMPTY_ITEM, EMPTY_ITEM] }

const AddProviderQueueModal = ({ handleClose, updateHistoryLog }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const providersList = useSelector(providersResultWithoutPaginationSelector)
  const providerSuggestionsList = useSelector(getProviderSuggestionsListSelector)
  const isCategoriesOptionsLoading = useSelector(getProviderSuggestionsListSelectorLoading)
  const TOAST_INITIAL_STATE = { isOpen: false, message: '', error: '' }
  const [toast, setToast] = useState(TOAST_INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const suggestedProviders = useSelector(providerSuggestionsSelector)
  const distributionActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DISTRIBUTION,
  })
  const [selectFilter, setSelectFilter] = useState('full')

  const providerSuggestionsListOptions = useMemo(() => {
    if (selectFilter === 'full') {
      return {
        tradesman: providerSuggestionsList?.map((item, index) => {
          return { name: `tradesman.${index}`, value: item }
        }),
      }
    }

    if (selectFilter === 'category') {
      return {
        tradesman: providersList
          .filter(provider => provider?.mainServices.some(item => item.toJS().parent === null))
          .map((item, index) => ({ name: `tradesman.${index}`, value: item })),
      }
    }

    if (selectFilter === 'subcategory') {
      return {
        tradesman: providersList
          .filter(provider => provider?.mainServices.some(item => item.toJS().parent !== null))
          .map((item, index) => ({ name: `tradesman.${index}`, value: item })),
      }
    }

    return { tradesman: {} }
  }, [providerSuggestionsList, selectFilter])

  const { control, getValues, watch, handleSubmit, reset } = useForm({
    defaultValues:
      providerSuggestionsListOptions?.tradesman?.length > 0
        ? providerSuggestionsListOptions
        : INITIAL_STATE,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tradesman',
  })

  const handleAppendItem = useCallback(() => {
    append(EMPTY_ITEM)
  }, [])

  const handleRemoveItem = useCallback(event => {
    const { index } = event.currentTarget.dataset
    remove(index)
  }, [])

  const canRemoveItem = useMemo(() => fields.length > 1, [fields])

  const handleCloseToast = useCallback(() => setToast(TOAST_INITIAL_STATE), [])

  const providerOptions = useMemo(() => {
    if (selectFilter === 'full') {
      return providersList?.map(item => ({
        value: item,
        name: item.name,
        label: item.name,
      }))
    }

    if (selectFilter === 'category') {
      return providersList
        .filter(provider => provider?.mainServices.some(item => item.toJS().parent === null))
        .map(item => ({
          value: item,
          name: item.name,
          label: item.name,
        }))
    }

    if (selectFilter === 'subcategory') {
      return providersList
        .filter(provider => provider?.mainServices.some(item => item.toJS().parent !== null))
        .map(item => ({
          value: item,
          name: item.name,
          label: item.name,
        }))
    }

    return []
  }, [providersList, selectFilter])

  const getOptionLabel = useCallback(
    option => {
      return (
        providerOptions?.find(opt => opt?.value?.tradesmanId === option?.value?.tradesmanId)
          ?.label || ''
      )
    },
    [providerOptions, selectFilter]
  )

  const getOptionSelected = useCallback(
    (option, currentValue) => {
      return Number(option?.value?.tradesmanId) === Number(currentValue?.value?.tradesmanId)
    },
    [selectFilter]
  )

  const onInputChange = useCallback(
    (inputLabel, name, onChange) => {
      const isValidOption = providerOptions.find(option => option.name === inputLabel) || null

      if (isValidOption) onChange({ name, value: isValidOption.value })
      if (inputLabel === '') onChange({ name: null, value: EMPTY_ITEM })
    },
    [providerOptions]
  )

  const renderSelect = useCallback(
    (item, index) => (
      <>
        <Grid item className={styles.fullWidth}>
          <Typography className={styles.label}>Selecione o prestador</Typography>
          <Controller
            control={control}
            name={`tradesman.${index}`}
            render={({ onChange, name, ...fieldProps }) => {
              return (
                <Autocomplete
                  {...fieldProps}
                  id={name}
                  options={providerOptions}
                  onChange={(event, newValue) => {
                    return onChange({ name, value: newValue.value })
                  }}
                  onInputChange={(event, newInputValue) => {
                    return onInputChange(newInputValue, name, onChange)
                  }}
                  multiple={false}
                  getOptionLabel={getOptionLabel}
                  getOptionSelected={getOptionSelected}
                  disableClearable
                  renderInput={params => <TextField {...params} />}
                />
              )
            }}
          />
        </Grid>
        {canRemoveItem && (
          <Grid item classes={{ item: styles.deleteButtonContainer }}>
            <IconButton
              variant="ghost"
              onClick={handleRemoveItem}
              data-index={index}
              className={styles.deleteButton}
            >
              <MinusCircleIcon color={Theme.Colors.Red.Base} />
            </IconButton>
          </Grid>
        )}
      </>
    ),
    [
      canRemoveItem,
      control,
      handleRemoveItem,
      providerOptions,
      styles.deleteButton,
      styles.deleteButtonContainer,
      styles.fullWidth,
      getOptionLabel,
      getOptionSelected,
      providerSuggestionsListOptions,
    ]
  )

  const handleSelect = useCallback(
    value => {
      setSelectFilter(value)
      updateList()
    },
    [selectFilter]
  )

  const updateList = useCallback((startIndex, endIndex) => {
    const { tradesman: tradesmanValue } = getValues()
    const reorderedTradesman = reorder(tradesmanValue, startIndex, endIndex)

    reset(
      { tradesman: reorderedTradesman },
      {
        keepErrors: true,
        keepDirty: true,
        keepTouched: true,
        keepSubmitCount: true,
      }
    )
  }, [])

  const convertToSlug = useCallback(
    text =>
      text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replaceAll(' ', '_'),
    []
  )

  const getTradesmanIncludesCurrentAgencyInBlockedAgencies = tradesmanIdsList => {
    const currentAgencyId = serviceOrder?.agency?.get('id')

    for (const tradesmanId of tradesmanIdsList) {
      const tradesman = providersList.find(provider => provider?.id === tradesmanId)

      if (tradesman?.mainBlockedAgencies?.includes(currentAgencyId)) {
        return tradesman.name
      }
    }

    return undefined
  }

  const handleSubmitFunction = useCallback(
    async data => {
      setIsLoading(true)
      const candidates = data.tradesman.filter(field => field.name).map(field => field.value.id)

      const candidateThatIncludesCurrentAgencyAsBlocked =
        getTradesmanIncludesCurrentAgencyInBlockedAgencies(candidates)

      if (candidateThatIncludesCurrentAgencyAsBlocked) {
        setIsLoading(false)
        setToast(prevState => ({
          ...prevState,
          isOpen: true,
          error: `O prestador ${candidateThatIncludesCurrentAgencyAsBlocked} não pode atender a imobiliária do chamado, conforme configurado em seu cadastro. Para prosseguir, selecione outro prestador.`,
        }))
      } else {
        await dispatch(
          createTradesmanSequence(serviceOrder.id, {
            isActive: true,
            serviceOrder: serviceOrder.id,
            candidates,
            suggestedProviders,
          })
        )
          .then(() => {
            updateHistoryLog()
            dispatch(getServiceOrder(serviceOrder?.id))
            handleClose()
            setIsLoading(false)
          })
          .catch(error => {
            setToast(prevState => ({
              ...prevState,
              isOpen: true,
              error: error[0],
            }))
            setIsLoading(false)
          })
      }
    },
    [serviceOrder.id, handleClose, suggestedProviders]
  )

  const disableSaveButton = useMemo(
    () =>
      isLoading ||
      getValues()?.tradesman?.every(field => !field?.name) ||
      !Object.keys(getValues()).length,
    [isLoading, watch()]
  )

  const defaultFilters = useMemo(() => {
    const excludeOnboardingStage = [
      'homologation',
      'waiting_list',
      'suspended',
      'paused',
      'recovery',
      'closing_process',
      'registration_closed',
    ]
    return serviceOrder
      ? {
          ...(serviceOrder.getIn(['property', 'city']) && {
            cities: convertToSlug(serviceOrder.getIn(['property', 'city'])),
          }),
          ...(serviceOrder.category?.first?.()?.get('id') && {
            mainServices: serviceOrder.category?.first?.()?.get('id'),
          }),
          registrationStatus: REGISTER_STATUS.COMPLETE,
          excludeOnboardingStage,
          serviceOrder: serviceOrder.id,
          referaSuggestion: false,
        }
      : {}
  }, [serviceOrder])

  const fetchProviders = useCallback(() => {
    if (!distributionActive) {
      dispatch(
        getGenericParameters({
          name: GENERIC_PARAMETERS.DISTRIBUTION,
        })
      ).then(data => {
        if (!data?.[0]?.active) {
          dispatch(getProvidersWithoutPagination(defaultFilters))
        }
      })
    }
  }, [dispatch])

  useEffect(() => {
    !distributionActive && fetchProviders()
  }, [fetchProviders, dispatch])

  const listProps = useMemo(
    () => ({
      item: true,
      xs: 12,
      classes: { item: styles.fieldList },
    }),
    [styles.fieldList]
  )

  const itemProps = useMemo(() => ({
    item: true,
    xs: 12,
    columnSpacing: '24',
    wrap: 'nowrap',
  }))

  // const customOnChange = useCallback(
  //   (event, callback) => {
  //     setIsLoading(true)

  //     dispatch(
  //       getProvidersWithoutPagination({
  //         ...defaultFilters,
  //         referaSuggestion: event.target.checked,
  //       })
  //     )
  //       .then(() => {
  //         setIsLoading(false)
  //         callback()
  //       })
  //       .catch(() => {
  //         setIsLoading(false)
  //         callback()
  //       })
  //   },
  //   [dispatch, defaultFilters]
  // )

  if (isCategoriesOptionsLoading) {
    return <Loading />
  }

  return (
    <>
      <form id="add-providers-queue">
        <Modal.Root open onClose={handleClose} disableEnforceFocus>
          <Modal.TitleModal>
            <Typography className={styles.title}>Selecionar prestador</Typography>
          </Modal.TitleModal>
          <Modal.Content className={styles.modalContent}>
            <Grid item xs={12}>
              {/* <ControlledSwitch
                name="referaSuggestions"
                label="Mostrar sugestões da Refera"
                control={control}
                className={styles.showReferaSuggestions}
                customOnChange={customOnChange}
                defaultValue={false}
              /> */}
              <Typography>
                Selecione os prestadores que vão participar da sequência de aceite desse chamado,
                por ordem de prioridade.{' '}
                {/* <Link href="/#" title="Sobre sequenciamento de prestador" underline="always">
                  Clique aqui para saber mais
                </Link> */}
              </Typography>
            </Grid>
            <FormControl className={styles.selectWrapper}>
              <Typography id="providerLabel" className={styles.selectLabel}>
                Filtro
              </Typography>
              <Select
                id="providerFilter"
                labelId="providerLabel"
                className={styles.select}
                value={selectFilter}
                defaultValue="full"
                onChange={e => handleSelect(e.target.value)}
              >
                <MenuItem key="1" value="full">
                  Completo
                </MenuItem>
                <MenuItem key="2" value="subcategory">
                  Subcategorias
                </MenuItem>
                <MenuItem key="3" value="category">
                  Só categorias
                </MenuItem>
              </Select>
            </FormControl>

            <DragAndDrop
              id="add-provider-queue-modal"
              list={fields}
              renderItem={fields?.length > 0 ? renderSelect : null}
              updateList={updateList}
              listProps={listProps}
              itemProps={itemProps}
            />

            <Grid item xs={12}>
              <Button
                variant="ghost"
                startIcon={<AddCircleIcon color={Theme.Colors.Primary.Base} />}
                onClick={handleAppendItem}
              >
                Adicionar outro prestador
              </Button>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Modal.ButtonRed onClick={handleClose}>Cancelar</Modal.ButtonRed>
            <Modal.ButtonFullBlue
              form="add-providers-queue"
              onClick={handleSubmit(handleSubmitFunction)}
              disabled={disableSaveButton}
            >
              Salvar
            </Modal.ButtonFullBlue>
          </Modal.Actions>
        </Modal.Root>
      </form>

      {toast.error && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity={toast.error && 'error'} title={toast.error} />
        </Toast>
      )}
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
    </>
  )
}

AddProviderQueueModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default React.memo(AddProviderQueueModal)
