import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Datagrid } from '@refera/ui-web'

import { getMessageSelector } from '_modules/mensageria/selector'
import { ROUTES } from '_utils/constants'
import { navigate } from '@reach/router'

import { COLUMNS } from './constants'
import { getMessaging } from '_/modules/mensageria/actions'
import { useToast } from '_/hooks/use-toast'

const MessageMainTable = () => {
  const dispatch = useDispatch()
  const messageList = useSelector(getMessageSelector)

  const [stateList, setStateList] = useState(messageList?.results || [])

  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')

  const { showToast } = useToast()

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('id')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleGetMessage = useCallback(() => {
    setIsLoading(true)

    return dispatch(getMessaging({ pageSize, page: page + 1, orderBy }))
      .then(data => {
        setStateList(data?.results || [])
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        return setIsLoading(false)
      })
  }, [dispatch, pageSize, page, orderBy])

  useEffect(() => {
    handleGetMessage()
  }, [dispatch, pageSize, page, orderBy])

  const handleRowClick = useCallback(event => {
    navigate(`${ROUTES.MENSAGERIA}/${event.row.id}`, 'noopener,noreferrer')
  }, [])

  const dataGridStyle = {
    fontSize: '1.4rem',
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  }

  return (
    <Datagrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        },
      }}
      rows={stateList}
      columns={COLUMNS.DEFAULT}
      paginationMode="server"
      sx={dataGridStyle}
      onRowClick={handleRowClick}
      loading={isLoading}
      rowCount={messageList?.count || 0}
      onPageChange={setPage}
      page={page}
      onPageSizeChange={setPageSize}
      pageSize={pageSize}
      onSortModelChange={handleOrderBy}
      noRowsLabel="Nenhum resultado encontrado"
    />
  )
}

export default React.memo(MessageMainTable)
