/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  MenuItem,
  Select,
  Chip,
  Divider,
} from '@material-ui/core'
import { FormControl, FormHelperText } from '@mui/material'
import { useParams } from '@reach/router'
import { useDispatch } from 'react-redux'

import Button, { BUTTON_COLOR } from '_components/button'
import TextField from '_components/textfield'
import { cancelServiceOrderFinish } from '_modules/service-orders/actions'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'

const OPTIONS = [
  { id: 'fotos_anexadas', reasons: 'Fotos anexadas' },
  { id: 'nota_fiscal_emitida', reasons: 'Nota Fiscal emitida' },
  { id: 'outros_motivos', reasons: 'Outros motivos' },
  { id: 'servicos_executados', reasons: 'Serviços executados' },
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const ERROR_MESSAGE_REQUESTED_FIELD = 'Este campo é obrigatório'
const ERROR_MESSAGE_REMAINING_CHARS = 'Número máximo de caractéres excedido'

const CancelConfirmModal = ({ cancelConfirmModal, setCancelConfirmModal, onCancelSuccess }) => {
  const style = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const [reasons, setReasons] = useState({
    optionsSelectedReprovedService: [],
    reasonsReproveService: '',
  })
  const [error, setError] = useState({
    optionsSelectedReprovedService: null,
    reasonsReproveService: null,
  })

  const handleChange = useCallback(({ target: { name, value } }) => {
    setReasons(prevState => ({ ...prevState, [name]: value }))
    setError(prevState => ({ ...prevState, [name]: null }))
  })

  const handleSubmit = useCallback(() => {
    const payload = {
      optionsSelectedReprovedService: reasons.optionsSelectedReprovedService.map(
        optionsSelected => optionsSelected.id
      ),
      reasonsReproveService: reasons.reasonsReproveService,
    }
    // Check for remaining characters
    if (remainingChars < 0) {
      setError(prevState => ({
        ...prevState,
        reasonsReproveService: ERROR_MESSAGE_REMAINING_CHARS,
      }))
    } else if (
      reasons.optionsSelectedReprovedService.length > 0 &&
      !!reasons.reasonsReproveService
    ) {
      dispatch(cancelServiceOrderFinish(serviceOrderId, payload))
        .then(() => onCancelSuccess(true))
        .catch(() => onCancelSuccess(false))
    } else {
      Object.keys(reasons).forEach(attribute => {
        if (!reasons[attribute] || reasons[attribute].length <= 0)
          setError(prevState => ({ ...prevState, [attribute]: ERROR_MESSAGE_REQUESTED_FIELD }))
      })
    }
  }, [reasons, serviceOrderId])

  const remainingChars = useMemo(() => {
    const maxLength = 510
    const value = reasons.reasonsReproveService
    const currentLength = value?.length ?? 0
    const numNewlines = (value.match(/\n/g) || []).length
    const charsLeft = maxLength - currentLength - numNewlines
    return charsLeft
  }, [reasons.reasonsReproveService])

  return (
    <Dialog
      open={cancelConfirmModal.isOpen}
      style={{ paper: style.dialog }}
      onClose={() => setCancelConfirmModal({ ...cancelConfirmModal, isOpen: false })}
    >
      <DialogTitle className={style.dialogTitle}>
        <Box mt={5} />
        <Typography variant="h6">{cancelConfirmModal.title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={style.dialogContent}>
        <Box mt={5} />
        <Typography className={style.labelText}>
          Descreva os motivos que justificam a reprovação dos serviços executados por tal prestador
          para que ele saiba como proceder.
        </Typography>

        <Typography className={style.text}>Relacionado a *</Typography>
        <FormControl fullWidth>
          <Select
            className={style.select}
            multiple
            name="optionsSelectedReprovedService"
            value={reasons.optionsSelectedReprovedService}
            onChange={handleChange}
            placeholder="Selecione os Motivos"
            error={!!error.optionsSelectedReprovedService}
            renderValue={selected => (
              <Box sx={{ display: 'block', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(value => (
                  <Chip key={value} label={value?.reasons} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {OPTIONS.map(option => (
              <MenuItem key={option?.id} value={option}>
                {option?.reasons}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!error.optionsSelectedReprovedService}>
            {error.optionsSelectedReprovedService}
          </FormHelperText>
        </FormControl>

        <Typography className={style.text}>Descreva o motivo *</Typography>
        <TextField
          className={style.input}
          variant="outlined"
          name="reasonsReproveService"
          value={reasons.reasonsReproveService}
          onChange={handleChange}
          multiline
          rows={6}
          error={!!error.reasonsReproveService}
          helperText={error.reasonsReproveService}
          inputProps={{ maxLength: 510 }}
        />
        <Typography id="remainingChars" className={style.counter}>
          {`${remainingChars} caracteres restantes`}
        </Typography>
        <ConfidentialDataWarning style={{ marginTop: 10 }} />
      </DialogContent>

      <DialogActions className={style.dialogAction}>
        <Button
          color={BUTTON_COLOR.PRIMARY}
          variant="outlined"
          className={style.button}
          onClick={() => setCancelConfirmModal({ ...cancelConfirmModal, isOpen: false })}
        >
          {cancelConfirmModal.textNoButton ? cancelConfirmModal.textNoButton : 'Cancelar'}
        </Button>

        {cancelConfirmModal ? (
          <Button
            variant="contained"
            color="primary"
            className={style.button}
            onClick={handleSubmit}
          >
            {cancelConfirmModal.textYesButton ? cancelConfirmModal.textYesButton : 'Confirmar'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={style.button}
            onClick={() => setCancelConfirmModal({ ...cancelConfirmModal, isOpen: false })}
          >
            {cancelConfirmModal.textYesButton ? cancelConfirmModal.textYesButton : 'Confirmar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CancelConfirmModal
